import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service7',
  templateUrl: './service7.component.html',
  styleUrls: ['./service7.component.css']
})
export class Service7Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
