import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-haber6',
  templateUrl: './haber6.component.html',
  styleUrls: ['./haber6.component.css']
})
export class Haber6Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
