<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Risk ALgısı</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/news"> Haberler</a></li>
        <li>Risk Algısı</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-riskalgisi.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />

  <p data-aos="fade-down" ><strong>Rİsk Algısı</strong></p>
  <br />

  <p data-aos="fade-left" >
    Risk algısı, ekonomiden politikaya, taşımacılıktan iş yeri güvenliğine pek
    çok farklı alanda ilgi uyandıran ve üzerinde çalışmalar yapılan bir konudur.
  </p>

  <p data-aos="fade-right" >
    1978'de yapılan bir çalışmanın sonuçları, risk algısında dehşet ve
    alışılmamışlığın iki önemli etken olduğunu ortaya koymuştur. Bunların yanı
    sıra, zaman da genel risk algısında zaman çok önemli bir etkendir.
  </p>

  <p data-aos="fade-left" >
    Yukarıdaki Şekil A'da Riskler belirlendiğinde önem dereceleri belirlenir ve
    Zamanla kişilerde bu risklerin önem seviyesi azalmaya başlar. Şekil B'de
    Risk algısının ciddi bir kaza sonrasında birden arttığını göstermektedir.
    Şekil C'de Kazadan sonra kişilerdeki risk algısının düştüğünü
    göstermektedir.
  </p>

  <p data-aos="fade-right" >
    İş sağlığı ve güvenlğinde de risk algısının sürekli olarak yüksekte
    tutulması iş kazalarını önlemede çok faydalı olacaktır. Risk algısının
    yüksek tutulması için ise düzenli aralılarla personellere eğitim verilmesi
    gerekir.
  </p>
</div>
