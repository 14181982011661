import { Component, OnInit } from '@angular/core';
import { Regulation } from './regulations';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor() { }

  filterText = ""

  regulations : Regulation[] = [
    {path:"https://www.mevzuat.gov.tr/MevzuatMetin/1.5.6331.pdf",name:"6331 İş Sağlığı ve Güvenliği Kanunu"},
    {path:"https://www.mevzuat.gov.tr/MevzuatMetin/1.5.4857.pdf",name:"4857 İş Kanunu"},
    {path:"https://www.mevzuat.gov.tr/MevzuatMetin/1.5.5510.pdf",name:"5510 Sosyal Sigortalar Kanunu"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.16924&amp;MevzuatIliski=0&amp;sourceXmlSearch",name:"İş Sağlığı ve Güvenliği Hizmetleri Yönetmeliği"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.16923&amp;MevzuatIliski=0",name:"İSG Uzmanlarının Görev, Yetki, Sorumlulukları Hakındaki Yönetmelik"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.18928&amp;MevzuatIliski=0&amp;sourceXmlSearch=yap%C4%B1%20i%C5%9Flerinde",name:"Yapı İşlernde İSG Yönetmelği"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.16925&amp;MevzuatIliski=0&amp;sourceXmlSearch=i",name:"İş Sağlığı ve Güvenliği Risk Değerlendirmesi Yönetmeliği"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.20992&amp;MevzuatIliski=0&amp;sourceXmlSearch=ilkyardım",name:"İlkyardım Yönetmeliği"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.18829&amp;MevzuatIliski=0&amp;sourceXmlSearch=",name:"Sağlık ve Güvenlik İşaretleri Yönetmeliği"},
    {path:"resmigazete.gov.tr/eskiler/2016/11/20161103-19.htm",name:"Basit Basınçlı Kaplar Yönetmeliği"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=3.5.200712937&amp;MevzuatIliski=0",name:"Binaların Yangından Korunması Hakkındaki Yönetmelik"},
    {path:"https://www.resmigazete.gov.tr/eskiler/2019/03/20190302-1.htm",name:"Büyük Endüstriyel Kazaların Önlenmesi Hakkındaki Yönetmelik"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.18371&amp;MevzuatIliski=0&amp;sourceXmlSearch",name:"Çalışanların İSG Eğitimlerinin Usul ve Esasları"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.5457&amp;MevzuatIliski=0&amp;sourceXmlSearch",name:"Çocuk ve Genç İşcilerin Çalıştırılması Hakkındaki Yönetmelik"},
    {path:"https://www.resmigazete.gov.tr/eskiler/2013/07/20130724-24.htm",name:"Elle Taşıma İşleri yönetmeliği"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.10391&amp;sourceXmlSearch=&amp;MevzuatIliski=0",name:"Elektrik İç Tesisleri Yönetmeliği"},
    {path:"resmigazete.gov.tr/eskiler/2013/07/20130705-3.htm",name:"Hijyen Eğitimi Yönetmeliği"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.18318&amp;MevzuatIliski=0",name:"İş Ekipmanın Kullanımında Sağlık ve Güvenlik Şartları Yönetmeliği"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.18540&amp;MevzuatIliski=0&amp;sourceXmlSearch=kişisel koruyucu",name:"Kişisel Koruyucu Donanımların İşyerinde Kullanılması Hakkındaki Yönetmelik"},
    {path:"https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=7.5.18858&amp;MevzuatIliski=0&amp;sourceXmlSearch",name:"Maden İşlerinde İSG Yönetmeliği"},
    
  
  ]
  

  ngOnInit(): void {
  }

}
