<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Yüksekte Çalışma Eğitimi</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/services"> Hizmetlerimiz</a></li>
        <li>Yüksekte Çalışma Eğitimi</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-yuksek.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />

  <div data-aos="fade-up">
    <h2>Yüksekte Çalışma Eğitimi</h2>
    <p>
      6331 Sayılı iş sağlığı ve güvenliği kanununa ve yönetmenliklerine göre
      çalşanlarIn yüksekte çalşma eğitimi alması gerektiği belirtilmiştir.
      Özellikle yapı işlerinde yüksekte düşme vakaları çok olmaktadIr. Ancak
      sadece yapı işlerinde çalşanlar değil tüm düşme sonucu yaralanma ihtimalin
      olacağı tüm iş alanları yüksekte çalşma olarak kabul edilmiştir.
      Yönetmenliğe göre de "<strong
        >seviye farkı bulunan ve düşme sonucu yaralanma ihtimalinin
        oluşabileceği her türlü alanda yapılan çalışma; yüksekte çalışma olarak
        kabul edilir</strong
      >"şeklinde belirtilmiştir. Yükseklik seviyesinden bağımsIz olarak
      yaralanma ihtimalinin olduğu her seviye yükseklik olarak tanImlanmştIr.
    </p>
    <p>
      Yüksekte çalışma eğitimi eğitmen sertifikasI olan uzmanlarımız tarafından 8
      saat teorik ve pratik olarak yapılmakta, eğitimi başarı ile geçen adaylara
      sertifikaları verilmektedir.
    </p>
  </div>

  <div data-aos="fade-left">
    <ul>
      <li>İlk YardIm Eğitimi</li>
      <li>Temel İş Sağlığı ve Güvenliği Eğitimi</li>
      <li>Yangın Eğitimi ve TatbikatI</li>
      <li>Yüksekte Çalşma Eğitimi</li>
    </ul>
  </div>

  <div data-aos="fade-right">
    <p><strong>Eğitim İçeriği</strong></p>
    <ul>
      <li>Askıda kalan kişiyi kurtarma yöntemleri</li>
      <li>Askıda kalma şoku ve süresi</li>
      <li>Düşme durdurma sistemleri</li>
      <li>Emniyet alma kuralları ( ankrajlama )</li>
      <li>Kişisel koruyucu donanımlarIn kontrol ve bakımı</li>
      <li>Merdiven, iskele ve platformlarda güvenli çalşma uygulamaları</li>
      <li>Sık kullanılan düğüm çeşitleri</li>
      <li>Toplu korunma uygulamaları ve kişisel koruyucu donanımlar</li>
      <li>Yüksekte çalışma ile ilgili tanımlar</li>
      <li>Yüksekte güvenli çalşmanın yasal dayanakları</li>
    </ul>
  </div>
</div>
