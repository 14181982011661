<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Tehlike ve Riskin Tanımı</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/news"> Haberler</a></li>
        <li>Tehlike ve Riskin Tanımı</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-tehlikevsrisk.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />

  <p data-aos="fade-down" ><strong>RİSK VE TEHLİKE</strong></p>
  <div data-aos="fade-left" >
    <p>
        Tehlike İşyerinde var olan ya da dışarıdan gelebilecek, çalışanı veya
        işyerini etkileyebilecek zarar veya hasar verme potansiyeli.
      </p>
    
      <p>
        Risk ise Tehlikeden kaynaklanacak kayıp, yaralanma ya da başka zararlı
        sonuç meydana gelme ihtimali olarak tanımlanmaktadır.
      </p>
    
      <p>
        Resime bakacak olursak tehlike ve riski anlamamız daha kolay olcaktır.
        Resimde köpek balığının denizde olması tehlikedir. Adamın denize girip köpek
        balığı tarafından ısırılıp yaralanması veya ölmesi ise risktir.
      </p>
  </div>
  
</div>
