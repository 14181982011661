<main id="main">
  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <h2>Referanslar</h2>
        <ol>
          <li><a routerLink="">Anasayfa</a></li>
          <li>Referanslar</li>
        </ol>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <div class="container" data-aos="fade-up">
    <img
      src="assets/images/erkar-harita.gif"
      class="img-fluid rounded mx-auto d-block"
      alt="Responsive image"
    /><br />
    <p>
      ERKAR OSGB olarak Erzurum ve çevresinde bulunan dokuz il de İş Sağlığı ve
      Güvenliği konularında sektöründe lider yüzlerce firmaya hizmet
      vermekteyiz.
    </p>
    <br />
  </div>

  <!-- ======= Portfolio Section ======= -->
  <section id="portfolio" class="portfolio">
    <div class="container">
      <div class="row portfolio-container" data-aos="fade-up">
        <div class="col-lg-4 col-md-6 portfolio-item filter-app" data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/1368086737_eeeee.jpg"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web" data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/abs.jpg"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/askelecim.png"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/bora.png"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/cebiotomotiv.jpg"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-app"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/dugumak.PNG"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/ergaz.png"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-card"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/ersesoto.jpg"
            class="img-fluid"
            alt=""
          />
        </div>

        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/esekerfab.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/hgg.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/hoctief.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/kaleblok-logo.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/teias.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/tema.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/oral.jpg"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/tepesavunma.png"
            class="img-fluid"
            alt=""
          />
        </div>
        <div class="col-lg-4 col-md-6 portfolio-item filter-web"data-aos="fade-up">
          <img
            src="assets/images/erkar-referans/turkmag-logo-color.png"
            class="img-fluid"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  <!-- End Portfolio Section -->
</main>
<!-- End #main -->

<!-- <div class="container">
    <img src="assets/images/erkar-harita.gif" style="margin-top: 100px;" class="img-fluid rounded mx-auto d-block" alt="Responsive image"><br>
    <p>ERKAR OSGB olarak Erzurum ve çevresinde bulunan dokuz il de İş Sağlığı ve Güvenliği konularında sektöründe lider yüzlerce firmaya hizmet vermekteyiz.</p><br>
    
    
</div> -->
