<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Risk Analizi</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/services"> Hizmetlerimiz</a></li>
        <li>Risk Analizi</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-rsk.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />

  <div data-aos="fade-right">
    <h2>Risk Analizi</h2>
    <p>
      Günümüzde işletmeler risk değerlendirmesi yardımıyla hatalara, kazalara,
      kayıplara neden olabilecek olayların önüne rahat bir şekilde
      geçebilmektedir. Risk değerlendirmesi, sadece olumsuzlukların önüne
      geçmez, bilinçli işletmelerin çalışanlarına tehlikesiz ve güvenilir bir iş
      ortamı sunmasını sağlar.
    </p>
  </div>

  <div data-aos="fade-left">
    <p>
      Risk değerlendirmesi, işyerinde olan veya dışarıdan gelebilecek
      tehlikelerin belirlenmesi ve bu tehlikelerle mücadele edilmesi,
      tehlikeleri riske dönüştüren faktörlerin analiz edilerek değerlendirilmesi
      ve kontrol tedbirlerinin kararlaştırılması amacıyla yapılması gerekli olan
      çalışmalardır. Risk değerlendirmesi çalışmalarında ortama, çalışanlara ve
      işyerine ilişkin tehlikeler tanımlanır. Riskler belirlenip analiz edilerek
      alınacak önlemler seçilir ve yapılan belirli hususları kapsayacak şekilde
      dokümanlar elde edilir.
    </p>
  </div>

  <div data-aos="fade-right">
    <p>
      Toplanan bilgi ve veriler ışığında belirlenen riskler, işletmenin
      faaliyetlerine ilişkin özellikleri belirlenen tehlikelerin nitelikleri ve
      işyerinin kısıtları gibi faktörler ulusal veya uluslararası standart
      yöntemler seçilerek analiz edilir.
    </p>
  </div>

  <div data-aos="fade-left">
    <p>
      İşveren; çalışma ortamının ve çalışanların sağlık ve güvenliğini sağlama,
      sürdürme ve geliştirme amacı ile iş sağlığı ve güvenliği yönünden risk
      değerlendirmesi yapar veya yaptırır.
    </p>
  </div>

  <div data-aos="fade-right">
    <p><strong>Risk değerlendirmesi bir ekip işidir.</strong></p>
    <p>
      Risk değerlendirmesi, işverenin oluşturduğu bir ekip tarafından
      gerçekleştirilir. Risk değerlendirmesi ekibi aşağıdakilerden oluşur.
    </p>
    <ul>
      <li>İşveren veya işveren vekili.</li>
      <li>
        İşyerinde sağlık ve güvenlik hizmetini yürüten iş güvenliği uzmanları
        ile işyeri hekimleri.
      </li>
      <li>İşyerindeki çalışan temsilcileri.</li>
      <li>İşyerindeki destek elemanları.</li>
      <li>
        İşyerindeki bütün birimleri temsil edecek şekilde belirlenen ve
        işyerinde yürütülen çalışmalar, mevcut veya muhtemel tehlike kaynakları
        ile riskler konusunda bilgi sahibi çalışanlar.
      </li>
    </ul>
  </div>

  <div data-aos="fade-left">
    <p>
      Risk değerlendirmesi; tüm işyerleri için tasarım veya kuruluş aşamasından
      başlamak üzere tehlikeleri tanımlama, riskleri belirleme ve analiz etme,
      risk kontrol tedbirlerinin kararlaştırılması, dokümantasyon, yapılan
      çalışmaların güncellenmesi ve gerektiğinde yenileme aşamaları izlenerek
      gerçekleştirilir.
    </p>
  </div>

  <div data-aos="fade-right">
    <p><strong>Risk değerlendirmesinin yenilenmesi</strong></p>
    <p>
      Yapılmış olan risk değerlendirmesi; tehlike sınıfına göre çok tehlikeli,
      tehlikeli ve az tehlikeli işyerlerinde sırasıyla en geç iki, dört ve altı
      yılda bir yenilenir.
    </p>
    <p>
      Aşağıda belirtilen durumlarda ortaya çıkabilecek yeni risklerin, işyerinin
      tamamını veya bir bölümünü etkiliyor olması göz önünde bulundurularak risk
      değerlendirmesi tamamen veya kısmen yenilenir.
    </p>
    <ul>
      <li>İşyerinin taşınması veya binalarda değişiklik yapılması.</li>
      <li>
        İşyerinde uygulanan teknoloji, kullanılan madde ve ekipmanlarda
        değişiklikler meydana gelmesi.
      </li>
      <li>Üretim yönteminde değişiklikler olması.</li>
      <li>İş kazası, meslek hastalığı veya ramak kala olay meydana gelmesi.</li>
      <li>
        Çalışma ortamına ait sınır değerlere ilişkin bir mevzuat değişikliği
        olması.
      </li>
      <li>
        Çalışma ortamı ölçümü ve sağlık gözetim sonuçlarına göre gerekli
        görülmesi.
      </li>
      <li>
        İşyeri dışından kaynaklanan ve işyerini etkileyebilecek yeni bir
        tehlikenin ortaya çıkması
      </li>
    </ul>
  </div>
  <br />
  <br />

  <div data-aos="fade-up">
    <strong
      >ER KAR OSGB, deneyimli uzman kadrosuyla iş yerlerinde risk değerlendirme
      ekipleri oluşturup ve oluşturulan ekipleri eğiterek olası tehlike ve
      riskleri ve bunlarla beraber kontrol önlemlerini tespit ederek risk
      değerlendirme hizmeti vermektedir. </strong
    ><br /><br />
  </div>
</div>
