import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service8',
  templateUrl: './service8.component.html',
  styleUrls: ['./service8.component.css']
})
export class Service8Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
