import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service3',
  templateUrl: './service3.component.html',
  styleUrls: ['./service3.component.css']
})
export class Service3Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
