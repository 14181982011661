import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { MainComponent } from './main/main.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { ReferencesComponent } from './references/references.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { OSGBNedirComponent } from './news/osgb-nedir/osgb-nedir.component';
import { IstatisliklerComponent } from './news/istatislikler/istatislikler.component';
import { KazaSebepleriComponent } from './news/kaza-sebepleri/kaza-sebepleri.component';
import { HaberComponent } from './news/haber/haber.component';
import { Haber5Component } from './news/haber5/haber5.component';
import { Haber6Component } from './news/haber6/haber6.component';
import { Service1Component } from './our-services/service1/service1.component';
import { Service2Component } from './our-services/service2/service2.component';
import { Service3Component } from './our-services/service3/service3.component';
import { Service4Component } from './our-services/service4/service4.component';
import { Service5Component } from './our-services/service5/service5.component';
import { Service6Component } from './our-services/service6/service6.component';
import { Service7Component } from './our-services/service7/service7.component';
import { Service8Component } from './our-services/service8/service8.component';




const routes: Routes = [
  {path:"contact",component:ContactComponent},
  {path:"main",component:MainComponent},
  {path:"about",component:AboutComponent},
  {path:"news",component:NewsComponent},
  {path:"news/OSGB-nedir",component:OSGBNedirComponent},
  {path:"news/Türkiyede-İş-Kazası-İstatislikleri",component:IstatisliklerComponent},
  {path:"news/İş-Kazası-Sebepleri",component:KazaSebepleriComponent},
  {path:"news/Risk-Algısı",component:HaberComponent},
  {path:"news/Mesleki-Yeterlilik-Belgesi",component:Haber5Component},
  {path:"news/Tehlike-Ve-Riskin-Tanımı",component:Haber6Component},
  {path:"references",component:ReferencesComponent},
  {path:"services",component:OurServicesComponent},
  {path:"services/Danışmanlık",component:Service1Component},
  {path:"services/İşyeri_Hekimliği",component:Service2Component},
  {path:"services/İşSağlığı-Ve-Güvenliği",component:Service3Component},
  {path:"services/İlk-Yardım-Eğitimi",component:Service4Component},
  {path:"services/Risk-Analizi",component:Service5Component},
  {path:"services/Hijyen-Eğitimi",component:Service6Component},
  {path:"services/Yüksekte-Çalışma-Eğitimi",component:Service7Component},
  {path:"services/Yangın-Eğitimi",component:Service8Component},
  {path:"",redirectTo:"main",pathMatch:"full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
