<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Türkiye'de İş Kazası istatislikleri</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/news"> Haberler</a></li>
        <li>Türkiye'de İş Kazası istatislikleri</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/haber-istatistik.webp"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />
  <div>
    <div data-aos="fade-right">
      <strong>SGK 2018 Kaza İstatistikleri</strong>
    </div>

    <div data-aos="fade-left">
      Dünyada ve ülkemizde eğitimsizlik, dikatsizlik, ihmal gibi konular sebebi
      ile iş kazları gerçeklerşerek can ve mal kayıplarına sebep olmaktadır. Biz
      de Çalışma ve Sosyal Güvenlik Bakanlığı nın yayınladığı verilere göre
      Türkiye’de yaşanan iş kazalarının ve meslek hastalıklarının hangi
      sektörlerde olduğunu inceledik. Faydası olacağını düşünerek sizlerin
      istifadenize sunuyoruz.
    </div>
    <br /><br />

    <div data-aos="fade-right">
      Sosyal Güvenlik Kurumunun 2018 yılı verilerine göre Türkiye’de toplam 430
      985 iş kazası gerçekleşmiştir. Kazaların oluşumu bakımından ilk üçte
      İnşaat Sektörü, Metal Ürerimi ve Gıda Üretimi sektörleri gelmektedir.
    </div>
    <br /><br />

    <div data-aos="fade-left">
      Yine Sosyal Güvenlik Kurumunun 2018 yılı verilerine göre Türkiye’de toplam
      1044 kişi Meslek Hastalığına yakalanmıştır. Meslek hastalıklarının
      görüldüğü ilk üç sektörde ise metalik olmayan mineral ürünlerin imalatı,
      Kömür ve Linyit Çıkartılması ve Ana metal sanayi sektörleri gelmektedir.
    </div>
  </div>
  <br /><img
    src="assets/images/erkar-sgk.PNG"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /> <br>
  <p data-aos="fade-up">
    Tablonun devamı için
    <a href="sgk-istatistik.xps"><strong>tıklayınız</strong></a
    >. Veriler SGK'nın web sitesinden alınmıştır. Ayrıntılı bilgiye
    <a
      href="http://www.sgk.gov.tr/wps/portal/sgk/tr/kurumsal/istatistik/sgk_istatistik_yilliklari"
      ><strong>buradan</strong></a
    >
    ulaşabilirsiniz.
  </p>
</div>
