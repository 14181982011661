<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>İşyeri Hekimliği</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/services"> Hizmetlerimiz</a></li>
        <li>İşyeri Hekimliği</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-isg.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />

  <div data-aos="fade-right">
    <h2>İSG Hizmetleri</h2>
    <p>
      İş güvenliği eğitim programını tamamlayan ve eğitim sonunda Bakanlıkça
      yapılacak veya yaptırılacak sınavda başarılı olan mimar, mühendis veya
      teknik elemanlara İş Güvenliği Uzmanı denir.
    </p>
    <p>
      (Teknik eleman: Teknik öğretmenler ile üniversitelerin fen/fen-edebiyat
      fakültesi fizik veya kimya bölüm mezunları veya İş güvenliği teknikerleri)
    </p>
  </div>

  <div data-aos="fade-left">
    <p>
      30.06.2012 Tarihli ve 6331 Sayılı İş Sağlığı ve Güvenliği Kanunu
      kapsamında işyerleri iş güvenliği uzmanı bulundurmak zorundadır. T.C.
      Çalışma Bakanlığı 27.11.2010 Tarihli İş Sağlığı ve Güvenliği Hizmetleri
      Yönetmeliğinin İşverenin Yükümlülüklerini belirleyen İkinci Bölümünün
      5.Maddesi şöyledir.
    </p>
    <p>
      "İşveren, işyerlerinde alınması gereken iş sağlığı ve güvenliği
      tedbirlerinin belirlenmesi ve uygulanmasının izlenmesi, iş kazası ve
      meslek hastalıklarının önlenmesi, işçilerin ilk yardım ve acil tedavi ile
      koruyucu sağlık ve güvenlik hizmetlerinin yürütülmesi amacıyla; İSGB
      oluşturmakla, bu birimde bir veya birden fazla işyeri hekimi ile gereğinde
      diğer sağlık personelini görevlendirmekle ve sanayiden sayılan işlerin
      yapıldığı işyerlerinde tehlike sınıfına uygun bir veya birden fazla iş
      güvenliği uzmanı görevlendirmekle yükümlüdür.
    </p>
  </div>

  <div data-aos="fade-right">
    <p>
      İşveren, birinci fıkrada sayılan yükümlülüklerinin tamamını veya bir
      kısmını işyerinde, Bakanlıkça belirlenen niteliklere sahip personel
      bulunmaması halinde, işletme dışında kurulu Bakanlıkça yetkilendirilen
      birimlerden (OSGB’lerden) hizmet alarak da yerine getirebilir."
    </p>
  </div>

  <div data-aos="fade-left">
    <h3>İş Güvenliği Uzmanının Sorumlulukları</h3>
    <p><strong>a) Rehberlik ve danışmanlık;</strong></p>
    <p>
      İşyerinde yapılan çalışmalar ve yapılacak değişikliklerle ilgili olarak
      tasarım, makine ve diğer teçhizatın durumu, bakımı, seçimi ve kullanılan
      maddeler de dâhil olmak üzere işin planlanması, organizasyonu ve
      uygulanması, kişisel koruyucu donanımların seçimi, temini, kullanımı,
      bakımı, muhafazası ve test edilmesi konularının, iş sağlığı ve güvenliği
      mevzuatına ve genel iş güvenliği kurallarına uygun olarak sürdürülmesini
      sağlamak için işverene tavsiyelerde bulunmak.
    </p>
  </div>

  <div data-aos="fade-right">
    <p><strong>b) Risk değerlendirmesi;</strong></p>
    <p>
      Risk değerlendirmesinin yapılmasını sağlamak; gerekli çalışmaları
      planlayarak alınacak sağlık ve güvenlik önlemleri konusunda işverene
      önerilerde bulunmak ve takibini yapmak.
    </p>
  </div>

  <div data-aos="fade-left">
    <p><strong>c) Çalışma ortamı gözetimi;</strong></p>
    <ul>
      <li>
        Çalışma ortamının gözetimini yapmak, işyerinde iş sağlığı ve güvenliği
        yönünden yapılması gereken periyodik bakım, kontrol ve ölçümleri
        planlamak ve uygulanmasını kontrol etmek.
      </li>
      <li>
        İşyerinde kaza, yangın veya patlamaların önlenmesi için mevzuata uygun
        çalışmalar yapmak ve uygulamaları takip etmek; doğal afet, kaza, yangın
        veya patlama gibi durumlar için acil durum planlarının hazırlanmasını
        sağlamak, periyodik olarak eğitimleri ve tatbikatları yaptırmak, acil
        durum planı doğrultusunda hareket edilmesini sağlamak.
      </li>
    </ul>
  </div>

  <div data-aos="fade-right">
    <p><strong>ç) Eğitim, bilgilendirme ve kayıt;</strong></p>
    <ul>
      <li>
        İş sağlığı ve güvenliği eğitimlerini ilgili mevzuata uygun olarak
        planlamak ve uygulamak.
      </li>
      <li>
        Çalışma ortamının gözetimi ile ilgili çalışmaları kaydetmek ve yıllık
        değerlendirme raporunu işyeri hekimi ile işbirliği yaparak hazırlamak.
      </li>
    </ul>
  </div>

  <div data-aos="fade-left">
    <p><strong>d) İlgili birimlerle işbirliği;</strong></p>
    <ul>
      <li>
        İşyeri hekimi ile işbirliği yaparak iş kazaları ve meslek hastalıkları
        ile ilgili değerlendirme yapmak, tehlikeli olayın tekrarlanmaması için
        inceleme ve araştırma yaparak gerekli önleyici faaliyet planlarını
        hazırlamak ve uygulamaların takibini yapmak.
      </li>
      <li>
        İşyeri hekimi ile işbirliği yaparak yıllık çalışma planını hazırlamak
      </li>
    </ul>
  </div>
</div>
