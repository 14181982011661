<main id="main">
  <!-- ======= Breadcrumbs ======= -->
  <section id="breadcrumbs" class="breadcrumbs">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center">
        <h2>Haberler</h2>
        <ol>
          <li><a routerLink="">Anasayfa</a></li>
          <li>Haberler</li>
        </ol>
      </div>
    </div>
  </section>
  <!-- End Breadcrumbs -->

  <!-- ======= Blog Section ======= -->
  <section id="blog" class="blog">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 entries">
          <article class="entry" data-aos="fade-up">
            <div class="entry-img">
              <img src="assets/images/686.jpg" alt="" class="img-fluid" />
            </div>

            <h2 class="entry-title">
              <a routerLink="/news/OSGB-nedir">OSGB NEDİR</a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="icofont-user"></i>
                  Abdullah Bekmez
                </li>
              </ul>
            </div>

            <div class="entry-content">
              <p>
                OSGB’ler, İş Sağlığı ve Güvenliği konularında şirketlerin
                yükümlülüklerini yerine getirmelerinde yardımcı olmak üzere
                Bakanlık tarafından yetkilendirilmiş uzman kuruluşlardır. İş
                Sağlığı ve Güvenliği Hizmetleri Yönetmeliği‘nin 4. Madde’sinin
                (f) şıkkında: “Ortak sağlık ve güvenlik birimi (OSGB): İş
                sağlığı ve güvenliği hizmetlerini sunmak üzere, gerekli donanım
                ve personele sahip olan ve Bakanlıkça yetkilendirilen kamu kurum
                ve kuruluşları ile Türk Ticaret Kanunu hükümlerine göre faaliyet
                gösteren şirketlerce kurulan ve işletilen müesseseleri,” olarak
                tanımlanmıştır.
              </p>
              <div class="read-more">
                <a routerLink="/news/OSGB-nedir">Daha Fazlası İçin</a>
              </div>
            </div>
          </article>
          <!-- End blog entry -->

          <article class="entry" data-aos="fade-up">
            <div class="entry-img">
              <img
                src="assets/images/istatistik.webp"
                alt=""
                class="img-fluid"
              />
            </div>

            <h2 class="entry-title">
              <a routerLink="/news/Türkiyede-İş-Kazası-İstatislikleri">Türkiyede iş Kazası İstatislikleri</a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="icofont-user"></i>
                  Ali Camadan
                </li>
                
              </ul>
            </div>

            <div class="entry-content">
              <p>
                Dünyada ve ülkemizde eğitimsizlik, dikatsizlik, ihmal gibi
                konular sebebi ile iş kazları gerçeklerşerek can ve mal
                kayıplarına sebep olmaktadır. Biz de Çalışma ve Sosyal Güvenlik
                Bakanlığı nın yayınladığı verilere göre Türkiye’de yaşanan iş
                kazalarının ve meslek hastalıklarının hangi sektörlerde olduğunu
                inceledik. Faydası olacağını düşünerek sizlerin istifadenize
                sunuyoruz.
              </p>
              <div class="read-more">
                <a routerLink="/news/Türkiyede-İş-Kazası-İstatislikleri">Daha Fazlası İçin</a>
              </div>
            </div>
          </article>
          <!-- End blog entry -->

          <article class="entry" data-aos="fade-up">
            <div class="entry-img">
              <img
                src="assets/images/erkar-1-iskazalari.jpg"
                alt=""
                class="img-fluid"
              />
            </div>

            <h2 class="entry-title">
              <a routerLink="/news/İş-Kazalarının-Sebepleri">İş Kazalarının Sebepleri</a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="icofont-user"></i>
                  <a href="blog-single.html">Hüseyin Bekmez</a>
                </li>
                
              </ul>
            </div>

            <div class="entry-content">
              <p>
                Hayatımızda yaşanan kazaların meydana gelmesinde üç faktör
                vardır. Bunlar tehlikeli davranış, tehlikeli durum ve doğal
                olaylardır.Yapılan araştırmalara göre insana bağlı tehlikeli
                davranış ve tehlikeli durum faktörleri iş kazalarının %98’ine
                sebep olmaktadır.
              </p>
              <div class="read-more">
                <a routerLink="/news/İş-Kazalarının-Sebepleri">Daha Fazlası İçin</a>
              </div>
            </div>
          </article>
          <!-- End blog entry -->

          <article class="entry" data-aos="fade-up">
            <div class="entry-img">
              <img
                src="assets/images/erkar-1-riskalgisi.jpg"
                alt=""
                class="img-fluid"
              />
            </div>

            <h2 class="entry-title">
              <a routerLink="/news/Risk-Algısı">Risk Algısı</a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="icofont-user"></i>
                 Abdullah Bekmez
                </li>
                
                  
              </ul>
            </div>

            <div class="entry-content">
              <p>
                Risk algısı,insanların riskin tespiti, özellikleri ve şiddeti
                hakkında yaptıkları yargıdır. Peki, risk algısı iş güvenliği
                faaliyetleri için nasıl geliştirilir? Algıyı geliştirmek için
                neler yapılabilir?
              </p>
              <div class="read-more">
                <a routerLink="/news/Risk-Algısı">Daha Fazlası İçin</a>
              </div>
            </div>
          </article>
          <!-- End blog entry -->
          <article class="entry" data-aos="fade-up">
            <div class="entry-img">
              <img
                src="assets/images/erkar-1-MESYET.jpg"
                alt=""
                class="img-fluid"
              />
            </div>

            <h2 class="entry-title">
              <a routerLink="/news/Mesleki-Yeterlilik-Belgesi">Mesleki Yeterlilik Belgesi</a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="icofont-user"></i>
                  Hüseyin Bekmez
                </li>
                
              </ul>
            </div>

            <div class="entry-content">
              <p>
                Mesleki Yeterlilik Belgesi, bireylerin herhangi bir meslekte,
                Mesleki Yeterlilik Kurumu (MYK) tarafından oluşturulmuş ulusal
                yeterliliklerde belirtilen şartları karşılamaları halinde sahip
                olabilecekleri bir belgedir.
              </p>
              <div class="read-more">
                <a routerLink="/news/Mesleki-Yeterlilik-Belgesi">Daha Fazlası İçin</a>
              </div>
            </div>
          </article>
          <!-- End blog entry -->
          <article class="entry" data-aos="fade-up">
            <div class="entry-img">
              <img
                src="assets/images/erkar-1-tehlikevsrisk.jpg"
                alt=""
                class="img-fluid"
              />
            </div>

            <h2 class="entry-title">
              <a routerLink="/news/Tehlike-Ve-Riskin-Tanımı">Tehlike Ve Riskin Tanımı</a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="icofont-user"></i>
                  Hüseyin Bekmez
                </li>
                
              </ul>
            </div>

            <div class="entry-content">
              <p>
                Tehlike ve Risk İş Sağlığı ve Güvenliğinde çok sık kullanılan
                kavramlar olup iyi anlaşılması gerekir.Tehlike İşyerinde var
                olan ya da dışarıdan gelebilecek, çalışanı veya işyerini
                etkileyebilecek zarar veya hasar verme potansiyeline sahip
                olaylar. Risk ise Tehlikeden kaynaklanacak kayıp, yaralanma ya
                da başka zararlı sonuç meydana gelme ihtimalini yanı kaybı ifade
                etmektedir.
              </p>
              <div class="read-more">
                <a routerLink="/news/Tehlike-Ve-Riskin-Tanımı">Daha Fazlası İçin</a>
              </div>
            </div>
          </article>
          <!-- End blog entry -->
          <article class="entry" data-aos="fade-up">
            <div class="entry-img">
              <img
                src="assets/images/erkar-1-iskazalari.jpg"
                alt=""
                class="img-fluid"
              />
            </div>

            <h2 class="entry-title">
              <a href="https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=9.5.16909&MevzuatIliski=0&sourceXmlSearch=işyeri tehli">İşyeri Tehlike Sınıfları Listesi</a>
            </h2>

            <div class="entry-meta">
              <ul>
                <li class="d-flex align-items-center">
                  <i class="icofont-user"></i>
                  Hüseyin Bekmez
                </li>
                
              </ul>
            </div>

            <div class="entry-content">
              <p>
                İşyerleri, çalışma alanlarına göre belli tehlike sınıflarına
                ayrılır. Böylece alınması gereken önlemleri sınıflandırmak ve
                çalışmalarını gerçekleştirmek daha kolay olur. İşyeri Tehlike
                sınıfları çok tehlikeli, tehlikeli ve az tehlikeli olmak üzere
                üçe ayrılır. Firmanızın hangi tehlike sınıfında olduğunu
                öğrenmek için tıklayınız.
              </p>
              <div class="read-more">
                <a href="https://www.mevzuat.gov.tr/Metin.Aspx?MevzuatKod=9.5.16909&MevzuatIliski=0&sourceXmlSearch=işyeri tehli">Daha Fazlası İçin</a>
              </div>
            </div>
          </article>
          <!-- End blog entry -->

          
        </div>
        <!-- End blog entries list -->

        <div class="col-lg-4">
          <div class="sidebar" data-aos="fade-left">
            <h3 class="sidebar-title">Mevzuatlar Ara</h3>
            <div class="sidebar-item search-form">
              <form action="">
                <input
                  id="regulations"
                  placeholder="Mevzuat Ara.. "
                  type="text"
                  [(ngModel)]="filterText"
                  name="filterText"
                />
              </form>
              <small *ngIf="filterText" class="form-text text-muted">
                Şu an <strong> {{ filterText }} </strong>aradınız...
              </small>
            </div>
            <!-- End sidebar search formn-->

            <h3 class="sidebar-title">Mevzuatlar</h3>
            <div class="sidebar-item categories">
              <ul>
                <li
                  *ngFor="
                    let regulation of regulations | regulationFilter: filterText
                  "
                >
                  <a href="{{ regulation.path }}">{{ regulation.name }}</a>
                </li>
              </ul>
            </div>
            <!-- End sidebar categories-->
          </div>
          <!-- End sidebar -->
        </div>
        <!-- End blog sidebar -->
      </div>
    </div>
  </section>
  <!-- End Blog Section -->
</main>
<!-- End #main -->
