import { Pipe, PipeTransform } from '@angular/core';
import { Regulation } from './regulations';

@Pipe({
  name: 'regulationFilter'
})
export class RegulationFilterPipe implements PipeTransform {

  transform(value: Regulation[], filterText?: string): Regulation[] {
    filterText = filterText?filterText.toLocaleLowerCase():null;

    return filterText?value.filter((p:Regulation)=>p.name.toLocaleLowerCase().indexOf(filterText)!==-1):value;
  }

}
