<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>OSGB Nedir?</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/news"> Haberler</a></li>
        <li>OSGB Nedir?</li>
      </ol>
    </div>
  </div>
</section>

<div class="container" data-aos="fade-up">
  <img
    src="assets/images/686.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
  /><br />
  <div>
    <strong>OSGB nedir? (Ortak Sağlık Güvenlik Birimi)</strong> <br /><br />

    <div>
      OSGB’ler,İş Sağlığı ve Güvenliği konularında şirketlerin yükümlülüklerini
      yerine getirmelerinde yardımcı olmak üzere Bakanlık tarafından
      yetkilendirilmiş uzman kuruluşlardır.
    </div>
    <br />

    İş Sağlığı ve Güvenliği Hizmetleri Yönetmeliği‘nin 4. Madde’sinin (f)
    şıkkında: <br />

    <div>
      “Ortak sağlık ve güvenlik birimi (OSGB):İş sağlığı ve güvenliği
      hizmetlerini sunmak üzere, gerekli donanım ve personele sahip olan ve
      Bakanlıkça yetkilendirilen kamu kurum ve kuruluşları ile Türk Ticaret
      Kanunu hükümlerine göre faaliyet gösteren şirketlerce kurulan ve işletilen
      müesseseleri,” olarak tanımlanmıştır. İşverenlerin yükümlülükleri (4857
      sayılıİş Kanunu) nelerdir?
    </div>
    <br />

    İş Kanunu’nun 77. ve sonraki diğer önemli maddeleri gereği olarak;<br />
    İşverenlerin, işyerlerinde iş sağlığı ve güvenliğinin sağlanması için
    gerekli her türlü önlemi almak, araç ve gereçleri noksansız bulundurmakla
    yükümlüdür. <br /><br />

    Dolayısıyla ;<br /><br />
    <ul>
      <li>
        İşverenlerin, işyerinde alınan iş sağlığı ve iş güvenliği önlemlerine
        uyulup uyulmadığını denetlemek, işçileri karşı karşıya bulundukları
        mesleki riskler, alınması gerekli tedbirler, yasal hak ve sorumlulukları
        konusunda bilgilendirmek ve gerekliİş Sağlığı ve Güvenliği Eğitimi’ni
        vermek zorundadır.
      </li>
      <br />
      <li>
        Her işverenin işyerlerinde, bir iş sağlığı ve güvenliği kurulu
        kurmakla,İş Güvenliği Uzmanı veİş Yeri Hekimi istihdam etmekle (veya
        yetkili OSGB’lerden temin etmekle) yükümlü olduklarını, İşverenlerin,
        işyerlerinde meydana gelen iş kazasını ve tespit edilecek meslek
        hastalığını yazı ile ilgili bölge müdürlüğüne bildirmek zorundadır.
      </li>
      <br />
      <li>
        Bir işyerinin tesis ve tertiplerinde, çalışma yöntem ve şekillerinde,
        makine ve cihazlarında işçilerin yaşamı için tehlikeli olan bir husus
        tespit edilirse, bu tehlike giderilinceye kadar işin tamamen veya kısmen
        durdurulur ya da kapatılır.
      </li>
      <br />
      <li>
        Bir kaza sonrasında yapılan incelemelerde,İş Sağlığı ve Güvenliği
        açısından temel önlemlerin alınmamış olması nedeniyle işverenin,
        “İşveren Kusuru” şartına bağlı olarak, toplam zararın en az 5 – 10 katı
        daha fazla tazminata maruz kalır.
      </li>
      <br />
      <li>
        <strong>6331 Sayılıİş Sağlığı ve Güvenliği Kanunu</strong>
      </li>
    </ul>
  </div>
  <br />
</div>
