import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http"

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './Components/navbar/navbar.component';
import { CarouselComponent } from './Components/carousel/carousel.component';
import { ServicesComponent } from './Components/services/services.component';
import { FooterComponent } from './Components/footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { MainComponent } from './main/main.component';
import { AboutComponent } from './about/about.component';
import { NewsComponent } from './news/news.component';
import { ReferencesComponent } from './references/references.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { RegulationFilterPipe } from './news/regulation-filter.pipe';
import { OSGBNedirComponent } from './news/osgb-nedir/osgb-nedir.component';
import { IstatisliklerComponent } from './news/istatislikler/istatislikler.component';
import { KazaSebepleriComponent } from './news/kaza-sebepleri/kaza-sebepleri.component';
import { HaberComponent } from './news/haber/haber.component';
import { Haber5Component } from './news/haber5/haber5.component';
import { Haber6Component } from './news/haber6/haber6.component';
import { Service1Component } from './our-services/service1/service1.component';
import { Service2Component } from './our-services/service2/service2.component';
import { Service3Component } from './our-services/service3/service3.component';
import { Service4Component } from './our-services/service4/service4.component';
import { Service5Component } from './our-services/service5/service5.component';
import { Service6Component } from './our-services/service6/service6.component';
import { Service7Component } from './our-services/service7/service7.component';
import { Service8Component } from './our-services/service8/service8.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    CarouselComponent,
    ServicesComponent,
    FooterComponent,
    ContactComponent,
    MainComponent,
    AboutComponent,
    NewsComponent,
    ReferencesComponent,
    OurServicesComponent,
    RegulationFilterPipe,
    OSGBNedirComponent,
    IstatisliklerComponent,
    KazaSebepleriComponent,
    HaberComponent,
    Haber5Component,
    Haber6Component,
    Service1Component,
    Service2Component,
    Service3Component,
    Service4Component,
    Service5Component,
    Service6Component,
    Service7Component,
    Service8Component,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
