import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kaza-sebepleri',
  templateUrl: './kaza-sebepleri.component.html',
  styleUrls: ['./kaza-sebepleri.component.css']
})
export class KazaSebepleriComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
