<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>İş Kazalarının Sebepleri</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/news"> Haberler</a></li>
        <li>İş Kazalarının Sebepleri</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-iskazalari.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />
  <div>
    <div data-aos="fade-down"><strong>İŞ Kazalarının Sebepleri</strong></div>
    <br /><br />

    <div data-aos="fade-down">
      Hayatımızda yaşanan kazaların meydana gelmesinde üç faktör vardır. Bunlar
      tehlikeli davranış, tehlikeli durum ve doğal olaylardır.Yapılan
      araştırmalara göre insana bağlı tehlikeli davranış ve tehlikeli durum
      faktörleri iş kazalarının %98’ine sebep olmaktadır. Tehlikeli durum ve
      Davranışlara örnek vermek gerekirse:
    </div>
    <br /><br />
    <div data-aos="fade-left">
      <p><strong>Tehlikeli Durumlar</strong></p>
      <ul>
        <li>Güvensiz çalışma yöntemi</li>
        <li>Güvensiz ve sağlıksız çevre koşulları</li>
        <li>Elektrikli makinelerde topraklama yapılmaması</li>
        <li>İşe uygun olmayan el aletleri</li>

        <li>Kontrol ve testlerin yapılmamış olması</li>
        <li>Basınçlı kaplar</li>
        <li>Tehlikeli yükseklikte çalışma ve/veya istifleme</li>
        <li>Açık bırakılan tehlikeli alanlar ( çukurlar..)</li>
        <li>Uygun işaretleme yapılmaması</li>
        <li>İş yeri düzensizliği• Koruyucusuz makine, tezgahlar</li>
        <li>Parlayıcı patlayıcı maddeler</li>
      </ul>
    </div>

    <div data-aos="fade-right">
      <p><strong>Tehlikeli Davranışlar;</strong></p>
      <ul>
        <li>İşi bilinçsiz yapmak,</li>
        <li>Dalgınlık ve dikkatsizlik</li>
        <li>Makina koruyucularını çıkarmak</li>
        <li>Tehlikeli hızla çalışmak</li>
        <li>Görevi dışında iş yapmak</li>
        <li>İş disiplinine uymamak</li>
        <li>İşe uygun makina kullanmamak</li>
        <li>Yetkisiz ve izinsiz olarak tehlikeli bölgede bulunmak</li>
        <li>Kişisel koruyucuları kullanmamak</li>
        <li>Ehil olmayan kişilerin makineleri kullanmalar</li>
      </ul>
      <br /><br />
    </div>
  </div>
</div>
