<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>İşyeri Hekimliği</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/services"> Hizmetlerimiz</a></li>
        <li>İşyeri Hekimliği</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/servis2.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />
  <div data-aos="fade-right">
    <p><strong>İşyeri Hekimliği</strong></p>
    <p>
      Son yıllarda dünyadaki gelişmelere paralel olarak ülkemizdeki sanayi ve
      teknoloji alanlarındaki gelişmeler; bu iş kollarında çalışan kişilerin
      sağlık ve güvenliği bakımından riskler ve sorunlar ortaya çıkarmıştır.
      Dünya Sağlık Örgütü; Çalışan sağlığı ve güvenliğini “çalışanların fiziki,
      ruhsal ve sosyal durumlarının en üst düzeye taşınması, sağlıklarına
      gelebilecek zararların önlenmesi için koruma yöntemlerinin uygulanması,
      kişinin işine ve işin kişiye uygunlu” olarak tanımlamaktadır.
    </p>
  </div>

  <div data-aos="fade-left">
    <p>
      <strong> İş sağlığı ve güvenliği hizmetleri yönetmeliğine göre;</strong>
    </p>
    <p>
      İşveren, işyerlerinde alınması gereken iş sağlığı ve güvenliği
      tedbirlerinin belirlenmesi ve uygulanmasının izlenmesi, iş kazası ve
      meslek hastalıklarının önlenmesi, çalışanların ilk yardım ve acil tedavi
      ile koruyucu sağlık ve güvenlik hizmetlerinin yürütülmesi amacıyla;
      çalışanları arasından bu Yönetmelikte belirtilen nitelikleri haiz bir veya
      birden fazla işyeri hekimi, iş güvenliği uzmanı ve diğer sağlık personeli
      görevlendirmekle yükümlüdür.
    </p>
  </div>

  <div data-aos="fade-right">
    <p>
      <strong>ERKAR OSGB</strong> olarak işyerlerinde sağlıklı ve güvenli bir
      çalışma ortamının tesis edilmesi, sağlık ve güvenlik risklerinin önlenmesi
      ve koruyucu hizmetlerin yürütülmesi için gerekli tedbirlerin belirlenmesi,
      bu tedbirlerin uygulanması ve uygulamaların izlenmesi işlerini yürütmek
      üzere İşyeri Hekimi Görevlendirilmesi ile gerektiğinde diğer sağlık
      personelinin görevlendirilmesi hizmetlerini gerçekleştirmekteyiz.
    </p>
  </div>

  <div data-aos="fade-left">
    <p>
      İş Sağlığı ve İş Güvenliği konularındaki deneyimi çok öncelere dayanan
      ERKAR OSGB hizmetlerini bireysel değil kurumsal bilgi birikimleri ile
      destekleyerek vermektedir.
    </p>
  </div>

  <div data-aos="fade-right">
    <p><strong>İşyeri hekiminin görevleri:</strong></p>
    <ul>
      <li>Rehberlik ve danışmanlık hizmetleri</li>
      <li>Sağlık gözetimi yapmak</li>
      <li>Eğitim ve bilgilendirme yapmak</li>
      <li>İlgili birimlerle işbirliği</li>
      <li>Koruyucu hekimlik önlemleri almak</li>
      <li>Arta kalan zamanlarda tedavi hizmetleri vermek</li>
    </ul>
  </div>

  <div data-aos="fade-left">
    <p><strong>İşyeri hekiminin verdiği eğitimler:</strong></p>

    <ul>
      <li>İş sağlığı etiği</li>
      <li>Kas iskelet sistemi hastalıkları</li>
      <li>İşyerinde ilkyardım örgütlenmesi</li>
      <li>Hijyen</li>
      <li>Çalışma yaşamında özel risk grupları</li>
    </ul>
  </div>
</div>
