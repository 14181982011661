<!-- ======= Hero Section ======= -->
<section id="hero">
  <div
    id="heroCarousel"
    class="carousel slide carousel-fade"
    data-ride="carousel"
  >
    <div class="carousel-inner" role="listbox">
      <!-- Slide 1 -->
      <div
        class="carousel-item active"
        style="background-image: url(assets/images/güvenlik.webp);"
      >
        <div class="carousel-container">
          <div class="carousel-content animated fadeInUp">
            <h2>İŞ SAĞLIĞI VE GÜVENLİĞİ</h2>
            <p>
              İş sağlığı ve güvenliği hayatımızda önemli bir role sahiptir.
              Kanuni olarak zorunlu görülsede İş sağlığı ve güvenliği
              hayatımızın vazgeçilmez bir parçasıdır.
            </p>
            <div class="text-center">
              <a
                routerLink="/services/İşSağlığı-Ve-Güvenliği"
                class="btn-get-started"
                >Daha Fazlası İçin</a
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Slide 2 -->
      <div
        class="carousel-item"
        style="background-image: url(assets/images/başlıklar.webp);"
      >
        <div class="carousel-container">
          <div class="carousel-content animated fadeInUp">
            <h2>OSGB NEDİR?</h2>
            <p>
              OSGB’ler,İş Sağlığı ve Güvenliği konularında şirketlerin
              yükümlülüklerini yerine getirmelerinde yardımcı olmak üzere
              Bakanlık tarafından yetkilendirilmiş uzman kuruluşlardır.
            </p>
            <div class="text-center">
              <a routerLink="/news/OSGB-nedir" class="btn-get-started">Daha Fazlası İçin</a>
            </div>
          </div>
        </div>
      </div>

      <!-- Slide 3 -->
      <div
        class="carousel-item"
        style="background-image: url(assets/images/karosel3.webp);"
      >
        <div class="carousel-container">
          <div class="carousel-content animated fadeInUp">
            <h2>İlk YARDIM EĞİTİMİ</h2>
            <p>
              İlk Yardım Öğren, Hayat Kurtar. İşverenlerin Acil durumlar için
              ilk yardım ve benzeri konularda müdahale yapacak yeteri sayıda
              yetkin personel/kişi bulundurması gerekmektedir.
            </p>
            <div class="text-center">
              <a
                routerLink="/services/İlk-Yardım-Eğitimi"
                class="btn-get-started"
                >Daha Fazlası İçin</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      class="carousel-control-prev"
      href="#heroCarousel"
      role="button"
      data-slide="prev"
    >
      <span
        class="carousel-control-prev-icon icofont-simple-left"
        aria-hidden="true"
      ></span>
      <span class="sr-only">Previous</span>
    </a>

    <a
      class="carousel-control-next"
      href="#heroCarousel"
      role="button"
      data-slide="next"
    >
      <span
        class="carousel-control-next-icon icofont-simple-right"
        aria-hidden="true"
      ></span>
      <span class="sr-only">Next</span>
    </a>

    <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>
  </div>
</section>
<!-- End Hero -->
