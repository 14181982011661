<!-- ======= Services Section ======= -->
<section id="services" class="services section-bg">
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2><strong> Hizmetlerimiz</strong></h2>
      <p>ÇALIŞANLARINIZIN SAĞLIĞI BİZE EMANET</p>
    </div>

    <div class="row">
      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <a routerLink="/services/Danışmanlık">
          <div class="icon-box iconbox-blue">
            <div class="icon">
              <img src="assets/images/erkar-i1.jpg" height="100px" alt="" />
            </div>
            <h4><a>Danışmanlık</a></h4>
            <p>
              Çalıma ve Sosyal Güvenlik Bakanlığı sertifikalı A, B, C Sınıfı iş
              Güvenliği Uzmanları ile konularında uzmanlaşmış mühendisler ile
              işletme ve işyerlerine sunmakta olduğumuz İş Sağlığı ve Güvenliği
              Danışmanlık hizmetlerimiz
            </p>
          </div>
        </a>
      </div>

      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <a routerLink="/services/İşyeri_Hekimliği">
          <div class="icon-box">
            <div class="icon">
              <img src="assets/images/erkar-i2.jpg" height="100px" alt="" />
            </div>
            <h4>
              <a>İşyeri Hekimliği</a>
            </h4>
            <p>
              İş Sağlığı ve İş Güvenliği konularındaki deneyimi çok öncelere
              dayanan <strong>ERKAR OSGB</strong> hizmetlerini bireysel değil
              kurumsal bilgi birikimleri ile destekleyerek vermektedir.
            </p>
          </div>
        </a>
      </div>

      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <a routerLink="/services/İşSağlığı-Ve-Güvenliği">
          <div class="icon-box iconbox-pink">
            <div class="icon">
              <img src="assets/images/erkar-i3.jpg" height="100px" alt="" />
            </div>
            <h4>
              <a>İş Sağlığı Ve Güvenliği Eğitimi</a>
            </h4>
            <p>
              Çalışanlarınızın sağlığı ve mutluluğu asıl hizmet amacımız olduğu
              için mesleklerini yaparlarken kendilerini korumalarını ve
              güvenliği sağlayacakları eğitimler veriyoruz.
            </p>
          </div>
        </a>
      </div>

      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <a routerLink="/services/İlk-Yardım-Eğitimi">
          <div class="icon-box iconbox-yellow">
            <div class="icon">
              <img src="assets/images/erkar-i4.jpg" height="100px" alt="" />
            </div>
            <h4>
              <a>İlk Yardım Eğitimi</a>
            </h4>
            <p>
              İlkyardımın eğitiminin amacı fertlerin ve toplumun temel sağlık
              bilgisinin artırılması, ilkyardım bilgi ve becerisinin topluma
              öğretilmesi, tüm kamu ve özel kurum/kuruluşlarında personel
              sayılarına göre ilkyardımcı bulundurulması ile kazalara bağlı ölüm
              ve sakatlık riskinin azaltılmasıdır.
            </p>
          </div>
        </a>
      </div>

      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <div class="icon-box iconbox-red">
          <div class="icon">
            <img src="assets/images/erkar-i5.jpg" height="100px" alt="" />
          </div>
          <h4>Laboratuvar Ölçümü</h4>
          <p>
            Çalışma alanlarınızın güvenliği için Laboratuvar ölçümleri yapıyoruz.
          </p>
        </div>
      </div>

      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <a routerLink="/services/Risk-Analizi">
          <div class="icon-box iconbox-teal">
            <div class="icon">
              <img src="assets/images/erkar-i6.jpg" height="100px" alt="" />
            </div>
            <h4><a>Risk Analizi</a></h4>
            <p>
              <strong>ERKAR OSGB</strong> ER KAR OSGB, deneyimli uzman
              kadrosuyla iş yerlerinde risk değerlendirme ekipleri oluşturup ve
              oluşturulan ekipleri eğiterek olası tehlike ve riskleri ve
              bunlarla beraber kontrol önlemlerini tespit ederek risk
              değerlendirme hizmeti vermektedir.
            </p>
          </div>
        </a>
      </div>

      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <a routerLink="/services/Hijyen-Eğitimi">
          <div class="icon-box iconbox-teal">
            <div class="icon">
              <img src="assets/images/erkar-i7.jpg" height="100px" alt="" />
            </div>
            <h4><a>Hijyen Eğitimi</a></h4>
            <p>
              Her iş kolunun özelliğine göre çalışanın kendisinin ve halkın
              sağlığını tehlikeye sokmayacak biçimde hizmet vermesini sağlamak
              amacıyla yapılacak uygulamaların ve alınan temizlik önlemlerinin
              bütünüdür.
            </p>
          </div>
        </a>
      </div>
      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <a routerLink="/services/Yüksekte-Çalışma-Eğitimi">
          <div class="icon-box iconbox-teal">
            <div class="icon">
              <img src="assets/images/erkar-i8.jpg" height="100px" alt="" />
            </div>
            <h4>
              <a>Yüksekte Çalışma Eğitimi</a>
            </h4>
            <p>
              Yüksekte çalışma eğitimi eğitmen sertifikasI olan uzmanlarımız
              tarafından 8 saat teorik ve pratik olarak yapılmakta, eğitimi
              başarı ile geçen adaylara sertifikaları verilmektedir.
            </p>
          </div>
        </a>
      </div>

      <div
        class="col-lg-4 col-md-6 d-flex align-items-stretch mt-4"
        data-aos="zoom-in"
        data-aos-delay="100"
      >
        <a routerLink="/services/Yangın-Eğitimi">
          <div class="icon-box iconbox-teal">
            <div class="icon">
              <img src="assets/images/erkar-i9.jpg" height="100px" alt="" />
            </div>
            <h4><a>Yangın Eğitimi</a></h4>
            <p>
              Kurum ve kuruluşların bünyesinde yer alan Tüm Personel ve Acil
              Müdahale Ekipleri görevlileri için profesyonel eğitimler
              veriyoruz.
            </p>
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<!-- End Services Section -->
