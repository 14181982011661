<!-- ======= Footer ======= -->
<footer id="footer"  >

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact">
            <h3>ERKAR OSGB</h3>
            <p>
              Yunus Emre Mah.1.Bayraktar Sok. No: 4A<br>
              Palandöken/ERZURUM<br>
               <br><br>
              <strong>Tel:</strong>  0 442 237 44 92<br>
              
              <strong>Email:</strong> erkarosgb@gmail.com<br>
            </p>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>Diğer Sayfalarımız İçin</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="">Anasayfa</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="about">Hakkımızda</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="services">Hizmetlerimiz</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="news">Haberler</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="references">Referanslar</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="references">Pricing</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="contact">İletişim</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Hizmetlerimiz</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="/services/Danışmanlık">Danışmanlık</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="/services/İşyeri_Hekimliği">İşyeri Hekimliği</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="/services/İşSağlığı-Ve-Güvenliği">İş Sağlığı Ve Güvebliği</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="/services/İlk-Yardım-Eğitimi">İlk Yardım Eğitimi</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="/services/Risk-Analizi">Risk Analizi</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="/services/Hijyen-Eğitimi">Hijyen Eğitimi</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="/services/Yüksekte-Çalışma-Eğitimi">Yüksekte Çalışma Eğitimi</a></li>
              <li><i class="bx bx-chevron-right"></i> <a routerLink="/services/Yangın-Eğitimi">Yangın Eğitimi</a></li>
            </ul>
          </div>

          

        </div>
      </div>
    </div>

    <div class="container d-md-flex py-4">

      <div class="mr-md-auto text-center text-md-left">
        <div class="copyright">
           Copyright &copy; <strong><span>2020</span></strong> ERKAR Ortak Sağlık Güvenlik Birimi
        </div>
        <div class="credits">
          <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: https://bootstrapmade.com/license/ -->
          <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/company-free-html-bootstrap-template/ -->
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </div>
      <div class="social-links text-center text-md-right pt-3 pt-md-0">
        
        <a href="https://www.facebook.com/erkarosgb/" class="facebook"><i class="bx bxl-facebook"></i></a>
        
        
        
      </div>
    </div>
  </footer><!-- End Footer -->