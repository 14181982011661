<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>İlk Yardım Eğitimi</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/services"> Hizmetlerimiz</a></li>
        <li>ilk Yardım Eğitimi</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-ilkyardm.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />

  <div data-aos="fade-right">
    <h2>TEMEL İLKYARDIM EĞİTİMİ</h2>
    <p>
      İlkyardımın eğitiminin amacı fertlerin ve toplumun temel sağlık bilgisinin
      artırılması, ilkyardım bilgi ve becerisinin topluma öğretilmesi, tüm kamu
      ve özel kurum/kuruluşlarında personel sayılarına göre ilkyardımcı
      bulundurulması ile kazalara bağlı ölüm ve sakatlık riskinin
      azaltılmasıdır.
    </p>
  </div>

  <div data-aos="fade-left">
    <p><strong>İlk yardım nedir?</strong></p>
    <p>
      İlkyardım herhangi bir kaza ya da yaşamı tehlikeye düşüren durumda, sağlık
      görevlilerinin yardımı sağlanıncaya kadar, hayatın kurtarılması ya da
      durumun daha kötüye gitmesini önleyebilmek amacıyla olay yerinde, tıbbi
      araç gereç aranmaksızın mevcut araç ve gereçlerle yapılan ilaçsız
      uygulamalardır.
    </p>
    <p>
      İş verenlerin İş sağlığı ve güvenliği kapsamında;
    </p>
    <ul>
      <li>Az tehlikeli işyerlerinde, her 20 çalışan için 1 ilkyardımcı,</li>
      <li>Tehlikeli işyerlerinde, her 15 çalışana kadar 1 ilkyardımcı,</li>
      <li>Çok tehlikeli işyerlerinde, her 10 çalışana kadar 1 ilkyardımcı,</li>
    </ul>
    <p>bulundurması zorunludur.</p>
  </div>

  <div data-aos="fade-right">
    <p><strong>Eğitimin içeriği </strong></p>
    <p>
      İlkyardım eğitimi için; genel ilkyardım bilgileri, temel yaşam desteği ve
      havayolu tıkanıklığında ilkyardım, bilinç bozukluklarında ilkyardım,
      kanamalar, şok ve göğüs ağrısında ilkyardım, yaralanmalarda
      ilkyardım,boğulmalarda ilkyardım, kırık, çıkık, burkulmalarda ilkyardım,
      acil taşıma teknikleri, böcek sokmaları ve hayvan ısırıklarında ilkyardım,
      zehirlenmelerde ilkyardım, yanık, donma ve sıcak çarpmalarında ilkyardım,
      göz, kulak, buruna yabancı cisim kaçmasında ilkyardım konularıdır.
    </p>
  </div>

  <div data-aos="fade-left">
    <p><strong>İlkyardımcı Belgesi Alma Şartları </strong></p>
    <ul>
      <li>
        İlkyardımcı olacak kişilerin en az ilkokul mezunu olması zorunludur.
      </li>
      <li>
        Katılımcıların, eğitim süresinin tamamına devam etmesi zorunludur.
      </li>
      <li>
        Eğitim sonunda katılımcıların başarılı sayılmaları için, teorik ve
        uygulamalı sınavların her birinden, 100 tam puan üzerinden 85 puan almış
        olmaları şartı aranır. Teorik sınavda başarılı olamayan katılımcılar
        uygulamalı sınava alınmaz.
      </li>
      <li>
        Sınav kurallarına uymadığı için sınav gözetmen ekiplerince hakkında
        tutanak tutulan katılımcılar, ilk sınav hakkından başarısız olmuş
        sayılır.
      </li>
      <li>
        Sınavlarda başarılı olamayan katılımcılar bir ay içerisinde tekrar
        sınava tabi tutulurlar. İki defa sınava girmesine rağmen başarısız olan
        katılımcılara yetki belgesi verilmez. Kişinin talebi olur ise merkez
        tarafından kursa katıldığına dair yazı verilir. İki sınavda başarısız
        olan katılımcı yeniden eğitime katılır.
      </li>
      <li>
        Teorik sınav soruları, müdürlük tarafından oluşturulan soru havuzundan
        sınav gözetim ekibince belirlenir.
      </li>
      <li>
        Uygulama sınavları; temel yaşam desteği (yetişkin, çocuk, bebek), hava
        yolu tıkanıklığı ve araç içinden yaralı çıkarma konuları mutlaka olmak
        kaydı ile beceri gerektiren diğer konularda yapılır. Konu başlıkları
        sınav gözetim ekibince belirlenir.
      </li>
    </ul>
  </div>

  <div data-aos="fade-right">
    <p><strong>İlkyardımcı belgesi: </strong></p>
    <p>
      İlkyardımcı belgesinin geçerlilik süresi üç yıldır. Geçerlilik süresinin
      bitimini takiben en geç 3 (üç) ay içerisinde güncelleme eğitimi alınması
      zorunludur. Belirtilen süreler içinde güncelleme eğitimine katılanların
      belgeleri yenilenir, katılmayanların belgeleri geçersiz sayılır.
    </p>
  </div>
</div>
