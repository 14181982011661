import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http"
import { environment } from 'src/environments/environment';
import { Service } from '../Components/services/services';
import { Observable } from 'rxjs';
 
@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private http:HttpClient) { }

  path = environment.path

  getServices():Observable<Service[]>{
   return this.http.get<Service[]>(this.path+"/service")
  }
}
