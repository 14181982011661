<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Mesleki Yeterlilik Belgesi</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/news"> Haberler</a></li>
        <li>Mesleki Yeterlilik Belgesi</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-MESYET.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />
  <p data-aos="fade-down" ><strong>MESLEKİ YETERLİLİK BELGESİ</strong></p>
  <p data-aos="fade-left" >
    Mesleki Yeterlilik Belgesi, bireylerin herhangi bir meslekte, Mesleki
    Yeterlilik Kurumu (MYK) tarafından oluşturulmuş ulusal yeterliliklerde
    belirtilen şartları karşılamaları halinde sahip olabilecekleri bir belgedir.
  </p>

  <p data-aos="fade-left">6331 sayılı iş kanununa göre</p>

  <p data-aos="fade-left"><strong>Çalışanların Eğitimi MADDE 17 </strong></p>
  <ul>
    <li data-aos="fade-left">
      İşveren, çalışanların iş sağlığı ve güvenliği eğitimlerini almasını
      sağlar. Bu eğitim özellikle; işe başlamadan önce, çalışma yeri veya iş
      değişikliğinde, iş ekipmanının değişmesi hâlinde veya yeni teknoloji
      uygulanması hâlinde verilir. Eğitimler, değişen ve ortaya çıkan yeni
      risklere uygun olarak yenilenir, gerektiğinde ve düzenli aralıklarla
      tekrarlanır.
    </li>
    <li data-aos="fade-left">Çalışan temsilcileri özel olarak eğitilir.</li>
    <li data-aos="fade-left">
      Mesleki eğitim alma zorunluluğu bulunan tehlikeli ve çok tehlikeli sınıfta
      yer alan işlerde, yapacağı işle ilgili mesleki eğitim aldığını
      belgeleyemeyenler çalıştırılamaz.
    </li>
    <li data-aos="fade-left">
      İş kazası geçiren veya meslek hastalığına yakalanan çalışana işe
      başlamadan önce, söz konusu kazanın veya meslek hastalığının sebepleri,
      korunma yolları ve güvenli çalışma yöntemleri ile ilgili ilave eğitim
      verilir. Ayrıca, herhangi bir sebeple altı aydan fazla süreyle işten uzak
      kalanlara, tekrar işe başlatılmadan önce bilgi yenileme eğitimi verilir.
    </li>
    <li data-aos="fade-left">
      Tehlikeli ve çok tehlikeli sınıfta yer alan işyerlerinde; yapılacak
      işlerde karşılaşılacak sağlık ve güvenlik riskleri ile ilgili yeterli
      bilgi ve talimatları içeren eğitimin alındığına dair belge olmaksızın,
      başka işyerlerinden çalışmak üzere gelen çalışanlar işe başlatılamaz.
    </li>
    <li data-aos="fade-left">
      Geçici iş ilişkisi kurulan işveren, iş sağlığı ve güvenliği risklerine
      karşı çalışana gerekli eğitimin verilmesini sağlar.
    </li>
    <li data-aos="fade-left">
      Bu madde kapsamında verilecek eğitimin maliyeti çalışanlara yansıtılamaz.
      Eğitimlerde geçen süre çalışma süresinden sayılır. Eğitim sürelerinin
      haftalık çalışma süresinin üzerinde olması hâlinde, bu süreler fazla
      sürelerle çalışma veya fazla çalışma olarak değerlendirilir.
    </li>
  </ul>
</div>
