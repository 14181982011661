<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Danışmanlık</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/services"> Hizmetlerimiz</a></li>
        <li>Danışmanlık</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/danışmanlık.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />
  <div data-aos="fade-down" >
    <p><strong>Danışmanlık</strong></p>

    <p>
      Çalıma ve Sosyal Güvenlik Bakanlığı sertifikalı A, B, C Sınıfı iş
      Güvenliği Uzmanları ile konularında uzmanlaşmış mühendisler ile işletme ve
      işyerlerine sunmakta olduğumuz
      <strong>İş Sağlığı ve Güvenliği Danışmanlık hizmetlerimiz</strong>
      aşağıdaki şekildedir;
    </p>
  </div>

  <div data-aos="fade-right" >
    <p><strong>Personel Görevlendirme</strong></p>

    <ul>
      <li>
        İşyerlerinde Çalıma ve Sosyal Güvenlik Bakanlığı’ndan Sertifikalı A-B-C
        Sınıfı iş Güvenliği Uzmanı görevlendirilmesi,
      </li>
      <li>İşyeri Hekimi görevlendirilmesi,</li>
      <li>İş Güvenliği Mühendisi Görevlendirilmesi,</li>
      <li>İş Güvenliği Teknik Elemanı Görevlendirilmesi,</li>
      <li>Sağlık Memuru Görevlendirilmesi.</li>
    </ul>
  </div>
  
<div data-aos="fade-left" >
    <p><strong>Denetimler ve Araştırmalar</strong></p>

    <ul>
      <li>İşyerlerinin iş Sağlığı ve Güvenliği açısından uygunluk denetimi,</li>
      <li>Risk Analizi Çalımaları,</li>
      <li>
        İş kazalarının araştırılması ve önlenmesi için aksiyon planlarının
        oluşturulması,
      </li>
      <li>Kaza raporlarının hazırlanması,</li>
      <li>Kaza kök neden analizi çalımaları,</li>
      <li>Güvenlik ve Sağlık işaretlerinin tespiti,</li>
    </ul>
</div>
  
<div data-aos="fade-right">
    <p><strong>Acil Durum Çalımaları</strong></p>

  <ul>
    <li>Acil Durum Ekiplerinin Oluşturulması,</li>
    <li>Acil durum ekiplerinin Eğitimi,</li>
    <li>Acil durum tatbikatları,</li>
    <li>Acil Durum Talimatlarının hazırlanması,</li>
    <li>Acil Durum Planlarının hazırlanması,</li>
    <li>Patlamadan korunma dokümanlarının hazırlanması.</li>
  </ul>
</div>
  
<div data-aos="fade-left" >
    <p><strong>Dokümantasyon ve Analiz Çalımaları</strong></p>

    <ul>
      <li>iş Sağlığı ve Güvenliği Kurulu iç yönetmeliğinin hazırlanması,</li>
      <li>Çalıma talimatlarının oluşturulması,</li>
      <li>Mevcut talimatların güncellenmesi,</li>
      <li>Görev, yetki ve sorumlulukların hazırlanması,</li>
      <li>Kaza istatistiklerinin oluşturulması,</li>
      <li>Ramak kala tespiti ve raporlanması,</li>
      <li>işyerinde kullanılacak kişisel koruyucu donanımların belirlenmesi,</li>
      <li>Çalışanların sağlık tetkiklerinin belirlenmesi,</li>
      <li>iş izni uygulamaları.</li>
    </ul>
</div>

<div data-aos="fade-right">
    <p><strong>Profesyonel Destek</strong></p>

  <ul>
    <li>iş Sağlığı ve Güvenliği Kurullarının oluşturulması</li>
    <li>iş Sağlığı ve Güvenliği Kurullarına katılım ve Profesyonel Destek,</li>
    <li>Üst ve Orta Düzey Yöneticiler için Durum Değerlendirmeleri,</li>
    <li>Sınırsız bilgi desteği,</li>

    <li>Güncel uygulamalar ve işletmesel çözümler hakkında destek,</li>
    <li>
      Tüm çalımaların yönetim sistemleri süreçlerine göre gerçekleştirilmesi,
    </li>
    <li>
      Alt işveren iş sağlığı ve güvenliği uygulamalarının gerçekleştirilmesi.
    </li>
  </ul>
</div>

  <div data-aos="fade-left">
    <p><strong>Yönetici ve Çalıanların Sertifikalı Eğitimleri</strong></p>

    <ul>
      <li>Yönetici iş Sağlığı ve Güvenliği Eğitimleri</li>
      <li>Eğitim planlarının oluşturulması</li>
      <li>Oryantasyon eğitimlerinin gerçekleştirilmesi</li>
      <li>
        Çalıanların iş Sağlığı ve Güvenliği Eğitimlerinin gerçekleştirilmesi
      </li>
      <li>Yangın eğitimi ve tatbikatı</li>
    </ul>
  </div>

  
</div>
