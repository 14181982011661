<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Yangın Eğitimi</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/services"> Hizmetlerimiz</a></li>
        <li>Yangın Eğitimi</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/erkar-1-yangin.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />

  <div data-aos="fade-down">
    <h2>Yangın Eğitimi</h2>
    <p>
      <strong>Amaç :</strong>Binaların yangından korunması hakkındaki yönetmelik
      Kapsamında Kurum ve Kuruluşlarda görev yapan tüm personelin: kullanılan
      her türlü yapı, bina, tesis ve işletmenin, işletimi, bakımı ve kullanımı
      safhalarında çıkabilecek yangınların en aza indirgemek, Personele yangın
      güvenliği bilgisini vermek, yangın yaratan riskleri tanımlamak, yangın
      çıkmaması için alınması gereken önlem ve davranış biçimlerini göstermek,
      yangın anındaki tahliye yöntem ve yollarını öğretmek
    </p>
    <p>
      <strong>Eğitime Katılacaklar :</strong>Kurum ve kuruluşların bünyesinde
      yer alan Tüm Personel ve Acil Müdahale Ekipleri görevlileri
    </p>
    <p>
      <strong>Verilecek Sertifika :</strong>Katılımcılara temel yangın eğitimi
      katılım sertifikası verilir.
    </p>
    <p><strong>Süre :</strong> 4 Saat (1 Saat Uygulama)</p>
  </div>

  <div data-aos="fade-right">
    <p>
      Yanma:Yanıcı maddenin, ısı ve oksijenle belirli oranlarda birleşmesi
      sonucu oluşan olaydır
    </p>
    <p>
      Hangi tipten olursa olsun yangının çıkabilmesi Yanıcı
      Madde-Oksijen-tutuşma sıcaklığı üçlüsünün varlığı ve uygun bir ortamda
      bulunmasını gerektirir. Bu üçlüye yangın üçgeni denir. Eğer bu üçlüden
      biri olmazsa veya gerekli oranda bulunmazsa yangın çıkmaz.
    </p>
    <div class="container">
      <img
        src="assets/images/yang%C4%B1n_clip_image002.gif"
        class="img-fluid rounded mx-auto d-block"
        alt="Responsive image"
        data-aos="zoom-in"
      /><br />
      <p>
        Yangın (Fire): Katı, sıvı veya gaz halindeki yanıcı maddelerin kontrol
        dışı yanma olayıdır.
      </p>
    </div>
  </div>

  <div data-aos="fade-left">
    <h2>Yangın Sınıfları</h2>
    <p><strong>A Sınıfı Yangınlar</strong></p>
    <p>
      Katımaddeler Alevli veya korlu olarak yanarlar. Alevli yanma maddenin
      yüzeyinde yanıcı buharların yanması şeklinde olur. Korlu yanmada yanma
      maddenin içine işler.Temel söndürme presibi soğutmadır.
    </p>
  </div>

  <div data-aos="fade-right">
    <p>
      <strong>B Sınıfı Yangınlar</strong>
    </p>
    <p>
      Yanabilen bütün sıvılar bu sınıfa girer. Petrol türevleri (benzin, benzol)
      mazot, yağlar, boyalar, katran, asfalt vb yangınlardır. Temel özellikleri
      korsuz ve alevli yanmalarıdır. Bu tip yangınlar hava ile teması keserek
      boğma suretiyle söndürülür.
    </p>
  </div>

  <div data-aos="fade-left">
    <p><strong>C Sınıfı Yangınlar</strong></p>
    <p>
      Gaz halindeki yanıcı madde yangınlardır. LPG, metan, propan, bütan,
      asetilen, doğalgaz, hidrojen, asetilen, kokgazı vb. gazların yangınlarını
      kapsar. Bu tip yangınlarda söndürmek üzere KKT, karbondioksit, Halongazı,
      su sisi, köpükkullanılabilir. Gaz yangınlarına müdahale ederken kural önce
      gaz akışını kesmek sonra södürmektir. Aksi takdirde ortamda gaz karışımı
      oluşur ve buda en ufak ısıkaynağı ile patlamaya ve yangının büyümesine
      sebep olacaktır.
    </p>
  </div>

  <div data-aos="fade-right">
    <p>
      <strong>D sınıfı yangınları</strong>
    </p>
    <p>Yana bilen hafif metal yangınlarıdır.</p>
    <ul>
      <li>
        Lityum, sodyum, potasyum, alüminyum, magnezyum, titanyum, karpit, çinko
        gibi yana bilen hafif ve aktif metaller ile radyoaktif maddeler gibi
        metal yangınları bu sınıfa girer.
      </li>
      <li>Temel özellikleri korlu, alevsiz yüksek sıcaklıkta yanmalarıdır.</li>
    </ul>
    <p>
      D sınıfı yangınların söndürme maddesi Kuru metal tozlu söndürücüdür. Kuru
      kum veya tuz ile yanan malzemenin üzeri örtülerek de söndürülebilir.
    </p>
  </div>

  <div data-aos="fade-left">
    <p><strong>E Sınıfı Yangınları</strong></p>
    <p>
      Elektrik Sebepli Yangınlardır.Elektrik akım kaçağının etrafa yayılması ve
      elektrik çarpması kazalarının meydana gelmesine sebep olan yangınlardır.
      Elektrik yangınlarında asla SU kullanılmaz. Karbondioksit-Kimyasal Toz
      kullanılarak söndürülür.
    </p>
  </div>

  <br />
  <br />
  <div data-aos="fade-right">
    <h3>
      Söndürme Metodları:
    </h3>
    <br />
    <p>
      Soğutma, Boğma, Yakıtı Giderme ve Zincirleme Reaksiyonu Engelleme olmak
      üzere dört adet söndürme yöntemi vardır.
    </p>
    <p><strong>1-Soğutma</strong></p>
    <ul>
      <li>Yanma sırasında yanıcı maddenin ısısı cinsine göre değişmektedir.</li>
      <li>
        Yanıcı maddeden ısı alınarak, sıcaklığını tutuşma derecesinin altına
        düşürmektir.
      </li>
    </ul>
  </div>

  <div data-aos="fade-up">
    <p><strong>2-Boğma (Hava ile irtibatı kesme)</strong></p>
    <p>
      Oksijen konsantrasyonunu yanma için gerekli oranın altına indirmektir.
      Mesela otomatik CO2 ‘liSöndürme sistemi dizayn edilirken ilgili hacimdeki
      Oksijenin oranını %15’ e indirecek miktar hesaplanır.
    </p>
    <p><strong>3-Yakıtı Giderme</strong></p>
    <p>Yanıcı maddeyi ortamdan kaldırma uygulamalarıdır.</p>
    <p><strong>4-Zincirleme Reaksiyonu Engelleme</strong></p>
    <p>
      Kuru Kimyevi Tozlar ve HalojenliHidrokarbonlar gibi bazı söndürme
      maddeleri yanıcı madde ile ısı üretmeyen reaksiyonlar meydana getirerek,
      alev üreten kimyasal reaksiyonu keserler, alevlenmeyi durdururlar.
    </p>
  </div>
  <br><br>
</div>
