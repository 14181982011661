import { Component, OnInit } from '@angular/core';
import { Service } from './services';
import { ServicesService } from 'src/app/Services/services.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
})
export class ServicesComponent implements OnInit {
  constructor(private serviceService: ServicesService) {}

  services: Service[];

  ngOnInit(): void {
    this.getServices()
  }

  getServices() {
    this.serviceService.getServices().subscribe((data) => {
      this.services = data;
    });
  }
}
