<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Hijyen Eğitimi</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li><a routerLink="/services"> Hizmetlerimiz</a></li>
        <li>Hijyen Eğitimi</li>
      </ol>
    </div>
  </div>
</section>

<div class="container">
  <img
    src="assets/images/hijyen.webp"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
    data-aos="zoom-in"
  /><br />

  <div data-aos="fade-up">
    <h2 >Hijyen Eğitimi</h2>
    <p>
      Her iş kolunun özelliğine göre çalışanın kendisinin ve halkın sağlığını
      tehlikeye sokmayacak biçimde hizmet vermesini sağlamak amacıyla yapılacak
      uygulamaların ve alınan temizlik önlemlerinin bütünüdür.
    </p>
  </div>
</div>
