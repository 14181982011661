
<header id="header" class="fixed-top" data-aos="zoom-in" >
    <div class="container d-flex align-items-center">

      
      
      <a routerLink="" class="logo mr-auto"><img style="max-height: 30px;" src="assets/images/erkar-logo.png" alt="" class="img-fluid"></a>
      
      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <li class="active"><a routerLink="">Anasayfa</a></li>
          <li><a routerLink="/about">Hakkımızda</a></li>
          <li><a routerLink="/services">Hizmetlerimiz</a></li>
          <li><a routerLink="/news">Haberler</a></li>
          
          <li><a routerLink="/references">Referanslar</a></li>
          <li><a routerLink="/contact">İletişim</a></li>

        </ul>
      </nav>

      <div class="header-social-links">
        
        <a href="https://www.facebook.com/erkarosgb/" > <i class="bx bxl-facebook"></i></a>
        
        
      </div>

    </div>
  </header> 
  

  