<main id="main">

    <!-- ======= Breadcrumbs ======= -->
    <section id="breadcrumbs" class="breadcrumbs">
      <div class="container">

        <div class="d-flex justify-content-between align-items-center">
          <h2>İletişim</h2>
          <ol>
            <li><a routerLink="">Anasayfa</a></li>
            <li>İletişim</li>
          </ol>
        </div>

      </div>
    </section><!-- End Breadcrumbs -->

    <!-- ======= Contact Section ======= -->
    <div style="margin-top: 50px;" class="map-section">
      <iframe style="border:0; width: 100%; height: 350px;" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.4305497119494!2d41.26810631491667!3d39.9093800942632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406e5f13c0afdf97%3A0xb30ac5293eff6d17!2zRVIgS0FSIE9SVEFLIFNBxJ5MSUsgR8OcVkVOTMSwSyBCxLBSxLBNxLA!5e0!3m2!1str!2str!4v1459112195909" frameborder="0" allowfullscreen></iframe>
    </div>

    <section id="contact" class="contact">
      <div class="container">

        <div class="row justify-content-center" data-aos="fade-up">

          <div class="col-lg-10">

            <div class="info-wrap">
              <div class="row">
                <div class="col-lg-4 info">
                  <i class="icofont-google-map"></i>
                  <h4>Adres:</h4>
                  <p>Yunus Emre Mah.1.Bayraktar Sok. No:4  <br>Palandöken/ERZURUM</p>
                </div>

                <div class="col-lg-4 info mt-4 mt-lg-0">
                  <i class="icofont-envelope"></i>
                  <h4>Email:</h4>
                  <p>erkarosgb@gmail.com</p>
                </div>

                <div class="col-lg-4 info mt-4 mt-lg-0">
                  <i class="icofont-phone"></i>
                  <h4>Tel:</h4>
                  <p>+90 442 237 44 92</p>
                </div>
              </div>
            </div>

          </div>

        </div>

        

      </div>
    </section><!-- End Contact Section -->

  </main><!-- End #main -->