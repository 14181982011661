<!-- ======= Breadcrumbs ======= -->
<section id="breadcrumbs" class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <h2>Hakkımızda</h2>
      <ol>
        <li><a routerLink="">Anasayfa</a></li>
        <li>Hakkımızda</li>
      </ol>
    </div>
  </div>
</section>
<!-- End Breadcrumbs -->

<!-- ======= About Us Section ======= -->
<div class="container" data-aos="fade-up">
  <img
    src="assets/images/erkar-1-hakmzd.jpg"
    class="img-fluid rounded mx-auto d-block"
    alt="Responsive image"
  /><br />
</div>
<section id="about-us" class="about-us">
  <div class="container" data-aos="fade-up">
    <div class="section-title aos-init aos-animate">
      <h1 style="margin-top: 50px;">Biz Kimiz?</h1>
      <p>
        ER KAR OSGB iş sağlığı ve güvenliği alanında yıllardır farklı firmalarda
        görev yapan tecrübeli uzman ve hekim kadrosuyla 2013 yılında
        kurulmuştur. Kurucu ortağı olan hekimlerin tecrübe, bilgi ve birikimleri
        ve deneyimli personel kadrosunun gücüyle iş sağlığı ve güvenliği
        alanında sürekli bir gelişim sağlamak ve hizmet verdiği firmalarda iş
        sağlığı ve güvenliği hizmetlerini üstün bir kaliteyle yürütmeyi
        amaçlamaktadır. ER KAR OSGB Erzurum merkezli olmak üzere tüm çevre
        illerinde müşterilerine hizmet vermektedir.
      </p>
    </div>

    <div class="row ">
      <div class=" col-lg-6" data-aos="fade-right">
        <h2  >VİZYONUMUZ</h2>
        <p>
          ER KAR OSGB olarak alanımızda öncü olmanın hedefiyle yola çıktık.
          Bizler bu hedefe emin adımlarla ilerlerken gücümüzü deneyim ve
          birikimlerimizin yanında bu işe olan bağlılığımızdan alıyoruz. Bu
          doğrultuda hizmet verdiğimiz tüm çalışma sahalarına bunu yansıtarak
          emin adımlarla ilerliyoruz.
        </p>
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left">
        <h2>MİSYONUMUZ</h2>
        <p>
          İş kazalarının minimize edildiği ve müşterilerimize iş güvenliği
          alanında avantaj sağlayan bir çözüm ortağı olmaktır. İş sağlığı ve
          güvenliği alanında edindiğimiz bilgi ve birikimleri çalışma hayatına
          aktararak, iş kazalarının minimize edildiği, güvenli ve sağlıklı
          çalışma ortamlarının daha çok olduğu bir Türkiye için yola çıkmış
          bulunmaktayız.
        </p>
        <!-- <ul>
          <li>
            <i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip
            ex ea commodo consequa
          </li>
          <li>
            <i class="ri-check-double-line"></i> Duis aute irure dolor in
            reprehenderit in voluptate velit
          </li>
          <li>
            <i class="ri-check-double-line"></i> Ullamco laboris nisi ut aliquip
            ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
          </li>
        </ul> -->
        
      </div>
    </div>
  </div>
</section>
<!-- End About Us Section -->
